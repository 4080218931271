<template>
	<div id="appMain">
		<div class="topMain">
			<div class="top">
				<div class="topOne">
					{{exam.testName}}
				</div>
				<div class="topTwo">
					<span>考试时间：{{exam.answerTime}}分钟</span>
					<span>考试总分：{{exam.totalScore}}分</span>
					<!-- <span>考试题数：{{exam.totalNum}}题</span> -->
					<span class="buttonCls" @click="sumbitEvt">交卷</span>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="contentLeft">
				<div class="card" v-if="exam.exams1.radio.length !== 0">
					<p>一、单项选择题</p>
					<div class="cardContent" v-for="(it,i) in exam.exams1.radio" :key="it.id">
						<h3>{{i+1}}.{{it.examName}} （{{it.examScore}}分）</h3>
						<el-radio-group v-model="it.answer" @change="radioEvt(it)">
							<el-radio v-for="item in it.options" :key="item.id" :label="item.idStr">
								{{item.idStr}}.{{item.item}}
							</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="card" v-if="exam.exams1.multiple.length !== 0">
					<p>二、多项选择题</p>
					<div class="cardContent" v-for="(it,i) in exam.exams1.multiple" :key="it.id">
						<h3>{{i+1}}.{{it.examName}} （{{it.examScore}}分）</h3>
						<el-checkbox-group v-model="it.answer" @change="multipleEvt(it)">
							<el-checkbox v-for="item in it.options" :key="item.id" :label="item.idStr">
								{{item.idStr}}.{{item.item}}
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="card" v-if="exam.exams1.judge.length !== 0">
					<p>三、判断题</p>
					<div class="cardContent" v-for="(it,i) in exam.exams1.judge" :key="it.id">
						<h3>{{i+1}}.{{it.examName}} （{{it.examScore}}分）</h3>
						<el-radio-group v-model="it.answer" @change="judgeEvt(it)">
							<el-radio :label="1">对</el-radio>
							<el-radio :label="2">错</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="card" v-if="exam.exams1.brief.length !== 0">
					<p>四、简答题</p>
					<div class="cardContent" style="margin-bottom:20px" v-for="(it,i) in exam.exams1.brief"
						:key="it.id">
						<h3>{{i+1}}.{{it.examName}} （{{it.examScore}}分）</h3>
						<el-input @change="briefEvt(it)" type="textarea" :rows="6" placeholder="请输入内容"
							v-model="it.answer">
						</el-input>
					</div>
				</div>
			</div>
			<div class="contentRight" :class="isFiexd ? 'isFiexd' : ''">
				<div class="rightTop">
					<div>答题卡 ({{count}}/{{total}})</div>
					<div>
						<p>剩余时间</p>
						<span>{{ result > 0 && result ? time2: ""}}</span>
					</div>
				</div>
				<div class="rightBottom">
					<div class="bottomCard">
						<h3>一、单项选择题</h3>
						<div class="cardSpan">
							<span @click="jumpEvt(i)" v-for="(it,i) in exam.exams1.radio"
								:style="it.radioStatus ? 'background:#e3e3e3' : ''" :key="i">{{i+1}}</span>
						</div>
					</div>
					<div class="bottomCard">
						<h3>二、多项选择题</h3>
						<div class="cardSpan">
							<span v-for="(it,i) in exam.exams1.multiple"
								:style="it.multipleStatus ? 'background:#e3e3e3' : ''" :key="i">{{i+1}}</span>
						</div>
					</div>
					<div class="bottomCard">
						<h3>三、判断题</h3>
						<div class="cardSpan">
							<span v-for="(it,i) in exam.exams1.judge"
								:style="it.judgeStatus ? 'background:#e3e3e3' : ''" :key="i">{{i+1}}</span>
						</div>
					</div>
					<div class="bottomCard">
						<h3>四、简答题</h3>
						<div class="cardSpan">
							<span v-for="(it,i) in exam.exams1.brief"
								:style="it.briefStatus ? 'background:#e3e3e3' : ''" :key="i">{{i+1}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				timer: null,
				result: null, //此变量为截止时间戳与当前时间戳相减的差
				timeStamp: null, //此变量为倒计时截止的时间戳
				time2: null,
				isFiexd: false,
				total: 0,
				exam: {
					exams1: {
						brief: [],
						judge: [],
						multiple: [],
						radio: [],
					}
				},
				count: 0,
				// 课程信息
				examList: {},
				paperId: ''
			}
		},
		created() {
			this.examList = this.$route.query.data
			this.paperId = localStorage.getItem('perId')
			//获取试卷试题
			this.$http.post('paperStart', {
				paperId: this.paperId
			}).then(res => {
				if (res.code === 200) {
					this.exam = res.data
					this.exam.exams1.multiple.map(it => {
						it.answer = []
						it.multipleStatus = false
						return it
					})
					this.exam.exams1.radio.map(it => {
						it.radioStatus = false
						return it
					})
					this.exam.exams1.judge.map(it => {
						it.judgeStatus = false
						return it
					})
					this.exam.exams1.brief.map(it => {
						it.briefStatus = false
						return it
					})
					this.countTime()
					this.total = this.exam.exams1.radio.length + this.exam.exams1.multiple.length + this.exam
						.exams1.judge.length + this.exam.exams1.brief.length
				} else {
					this.$message.error(res.message)
				}
			})

		},

		mounted() {
			//监听滚动事件
			window.addEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			//单选
			radioEvt(e) {
				if (!e.radioStatus) {
					this.count++
				}
				e.radioStatus = true
			},
			//多选
			multipleEvt(e) {
				if (!e.multipleStatus) {
					this.count++
				}
				e.multipleStatus = true
			},
			//判断
			judgeEvt(e) {
				if (!e.judgeStatus) {
					this.count++
				}
				e.judgeStatus = true
			},
			//解答题
			briefEvt(e) {
				if (!e.briefStatus) {
					this.count++
				}
				e.briefStatus = true
				if (!e.answer) {
					e.briefStatus = false
					this.count--
				}
			},
			handleCurrentChange(e) {
				console.log(e);
			},
			//滚动事件
			handleScroll(e) {
				if (e.target.scrollTop > 134) {
					this.isFiexd = true
				}
				if (e.target.scrollTop <= 134) {
					this.isFiexd = false
				}
			},
			//点击跳转当前题目
			jumpEvt(index) {
				let target = document.querySelector('#appMain')
				let scrollItems = document.querySelectorAll('.cardContent')
				let numTotal = 0
				for (var i = 0; i <= index; i++) {
					let num = scrollItems[i].offsetHeight
					numTotal += num
				}
				target.scrollTop = numTotal
			},
			//倒计时时间
			countTime() {
				//如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
				let currentStamp = new Date().getTime();
				this.timeStamp = new Date().getTime() + this.exam.answerTime * 60 * 1000; //设置倒计时时间
				this.result = (this.timeStamp - currentStamp) / 1000;

				this.timer = setInterval(() => {
					this.time2 = this.formatSeconds(this.result)
					this.result--;
					if (this.result === 0) {
						this.submitExam()
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			},
			//时间转换时分秒
			formatSeconds(value) {
				let result = parseInt(value)
				let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
					60 % 60));
				let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

				let res = '';
				if (h !== '00') res += `${h}时`;
				if (m !== '00') res += `${m}分`;
				res += `${s}秒`;
				return res;
			},
			// 交卷封装
			submitExam(){
				let data = {
							courseId: this.exam.courseId,
							paperId: this.exam.paperId,
							items: []
						}
						//简单题
						this.exam.exams1.brief.map(it => {
							data.items.push({
								answer: it.answer,
								id: it.id,
								type: it.examType
							})
						})
						//单选
						this.exam.exams1.radio.map(it => {
							data.items.push({
								answer: it.answer,
								id: it.id,
								type: it.examType
							})
						})
						//多选
						this.exam.exams1.multiple.map(it => {
							data.items.push({
								answer: it.answer.toString().replace(/,/g, ""),
								id: it.id,
								type: it.examType
							})
						})
						//判断
						this.exam.exams1.judge.map(it => {
							data.items.push({
								answer: it.answer === 1 ? '对' : '错',
								id: it.id,
								type: it.examType
							})
						})
						// 交卷
						this.$http.post2('handPaper', data).then(res => {
							if (res.code === 200) {
								this.$message.success('交卷成功')
								// this.$router.push({
								//   path: '/examination/viewTest',
								//   quety: {data:this.examList }
								// })
								this.$router.push({
									path: '/personalCenter',
									query: {
										index: 6,
										infoIndex: 4
									}
								})
							} else {
								this.$message.error(res.message)
							}
						})
			},
			//交卷
			sumbitEvt() {
				let msg = '您还有试题没有做完，确定交卷？';
				if (this.count === this.exam.totalNum) {
					msg = '确认交卷？'
				} 
				this.$confirm(msg, '温馨提示', {
						confirmButtonText: '确定交卷',
						cancelButtonText: '取消',
						type: 'info'
					}).then(() => {
						this.submitExam()
					})
			}
		}
	}
</script>

<style lang="less" scoped>
	#appMain {
		height: 100%;
		overflow: auto;
	}

	.main {
		width: 1820px;
		margin: 0 auto;
	}

	.topMain {
		height: 94px;
		background: #FFFFFF;
		box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.05);
		margin-bottom: 40px;
		width: 100%;
		padding: 0 40px;
	}

	.top {
		display: flex;
		justify-content: space-between;
		line-height: 94px;

		.topOne {
			font-size: 30px;
			font-family: Adobe Heiti Std;
			font-weight: bold;
			color: #111111;
			width: 250px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.topTwo {
			display: flex;

			span {
				display: block;
				margin-left: 50px;
				font-size: 22px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #111111;

				&:first-child {
					margin-left: 0;
				}
			}

			.buttonCls {
				width: 227px;
				height: 65px;
				background: #E51E0B;
				color: #FFFFFF;
				text-align: center;
				margin-top: 15px;
				line-height: 65px;
				cursor: pointer;
			}
		}
	}

	.content {
		display: flex;
		justify-content: space-between;
		padding: 0 43px 0 47px;

		.contentLeft {
			// width: 1335px;
			flex: 2;
			overflow-y: auto;

			.card {
				margin-bottom: 30px;

				p {
					height: 47px;
					background: #DCDCDC;
					font-size: 24px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #333333;
					line-height: 47px;
					padding-left: 10px;
					margin-bottom: 20px;
				}

				.cardContent {
					margin-left: 27px;
				}

				h3 {
					// width: 1308px;
					// height: 47px;
					background: #DCDCDC;
					font-size: 18px;
					font-family: SourceHanSerifCN;
					font-weight: 500;
					color: #333333;
					line-height: 47px;
					margin-bottom: 10px;
					padding-left: 10px;
				}

				/deep/.el-radio-group {
					margin-left: 20px;
				}

				/deep/.el-radio {
					display: block;
					margin-bottom: 15px;
					font-size: 16px;
					font-family: SourceHanSerifCN;
					font-weight: 400;
					color: #333333;
				}

				/deep/.el-radio__input.is-checked+.el-radio__label {
					color: #333333;
				}

				/deep/.el-radio__input.is-checked .el-radio__inner {
					border-color: #08A500;
					background: #08A500;
				}

				/deep/.el-checkbox {
					display: block;
					margin-bottom: 15px;
					margin-left: 20px;
				}

				/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
					color: #333333;
				}

				/deep/.el-checkbox__inner {
					border-radius: 100%;
				}

				/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
				.el-checkbox__input.is-indeterminate .el-checkbox__inner {
					background-color: #08A500;
					border-color: #08A500;
				}
			}
		}

		.contentRight {
			width: 452px;
			height: 750px;
			background: #FFFFFF;
			box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.09);
			margin-left: 30px;

			.rightTop {
				display: flex;

				div:nth-child(1) {
					width: 50%;
					height: 65px;
					background: #041366;
					font-size: 24px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
					text-align: center;
					line-height: 65px;
				}

				div:nth-child(2) {
					width: 50%;
					height: 65px;
					text-align: center;

					p {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #E51E0B;
					}

					span {
						font-size: 30px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #E51E0B;
					}
				}
			}

			.rightBottom {
				padding: 0 30px;
				margin-top: 50px;

				.bottomCard {
					margin-bottom: 20px;
				}

				h3 {
					font-size: 24px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #333333;
				}

				.cardSpan {
					display: flex;
					flex-wrap: wrap;
					margin-top: 20px;

					span {
						display: block;
						width: 30px;
						height: 30px;
						border: 1px solid #DCDCDC;
						margin-right: 6px;
						margin-bottom: 6px;
						text-align: center;
						line-height: 30px;
						font-size: 14px;
						font-family: SourceHanSerifCN;
						font-weight: 400;
						color: #333333;
						cursor: pointer;
					}
				}
			}
		}

		// .isFiexd {
		// 	position: fixed;
		// 	top: 30px;
		// 	right: 59px;
		// }
	}

	/deep/.el-pagination {
		text-align: center;
		margin: 30px 0;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #E51E0B;
	}
</style>
